(function ($) {
  // Used by dateinput
  $.expr = { ":": {} };

  // Used by bootstrap
  $.support = {};

  // Used by dateinput
  $.fn.clone = function () {
    var ret = $();
    this.each(function () {
      ret.push(this.cloneNode(true));
    });
    return ret;
  };

  ["Left", "Top"].forEach(function (name, i) {
    var method = "scroll" + name;

    function isWindow(obj) {
      return obj && typeof obj === "object" && "setInterval" in obj;
    }

    function getWindow(elem) {
      return isWindow(elem)
        ? elem
        : elem.nodeType === 9
        ? elem.defaultView || elem.parentWindow
        : false;
    }
    $.fn[method] = function (val) {
      var elem, win;

      if (val === undefined) {
        elem = this[0];

        if (!elem) {
          return null;
        }

        win = getWindow(elem);

        // Return the scroll offset
        return win
          ? "pageXOffset" in win
            ? win[i ? "pageYOffset" : "pageXOffset"]
            : win.document.documentElement[method] || win.document.body[method]
          : elem[method];
      }

      // Set the scroll offset
      this.each(function () {
        win = getWindow(this);

        if (win) {
          var xCoord = !i ? val : $(win).scrollLeft();
          var yCoord = i ? val : $(win).scrollTop();
          win.scrollTo(xCoord, yCoord);
        } else {
          this[method] = val;
        }
      });
    };
  });

  // Used by colorslider.js
  ["width", "height"].forEach(function (dimension) {
    var offset,
      Dimension = dimension.replace(/./, function (m) {
        return m[0].toUpperCase();
      });
    $.fn["outer" + Dimension] = function (margin) {
      var elem = this;
      if (elem) {
        var size = elem[dimension]();
        var sides = { width: ["left", "right"], height: ["top", "bottom"] };
        sides[dimension].forEach(function (side) {
          if (margin) size += parseInt(elem.css("margin-" + side), 10);
        });
        return size;
      } else {
        return null;
      }
    };
  });

  // Used by bootstrap
  $.proxy = function (fn, context) {
    if (typeof context === "string") {
      var tmp = fn[context];
      context = fn;
      fn = tmp;
    }

    // Quick check to determine if target is callable, in the spec
    // this throws a TypeError, but we will just return undefined.
    if (!$.isFunction(fn)) {
      return undefined;
    }

    // Simulated bind
    var args = Array.prototype.slice.call(arguments, 2),
      proxy = function () {
        return fn.apply(
          context,
          args.concat(Array.prototype.slice.call(arguments))
        );
      };

    // Set the guid of unique handler to the same of original handler, so it can be removed
    proxy.guid = fn.guid = fn.guid || proxy.guid || $.guid++;

    return proxy;
  };

  // Used by timeago
  var nativeTrim = String.prototype.trim;
  $.trim = function (str, characters) {
    if (!characters && nativeTrim) {
      return nativeTrim.call(str);
    }
    characters = defaultToWhiteSpace(characters);
    return str.replace(
      new RegExp("^[" + characters + "]+|[" + characters + "]+$", "g"),
      ""
    );
  };

  // Used by util.js
  var rtable = /^t(?:able|d|h)$/i,
    rroot = /^(?:body|html)$/i;
  $.fn.position = function () {
    if (!this[0]) {
      return null;
    }

    var elem = this[0],
      // Get *real* offsetParent
      offsetParent = this.offsetParent(),
      // Get correct offsets
      offset = this.offset(),
      parentOffset = rroot.test(offsetParent[0].nodeName)
        ? { top: 0, left: 0 }
        : offsetParent.offset();

    // Subtract element margins
    // note: when an element has margin: auto the offsetLeft and marginLeft
    // are the same in Safari causing offset.left to incorrectly be 0
    offset.top -= parseFloat($(elem).css("margin-top")) || 0;
    offset.left -= parseFloat($(elem).css("margin-left")) || 0;

    // Add offsetParent borders
    parentOffset.top +=
      parseFloat($(offsetParent[0]).css("border-top-width")) || 0;
    parentOffset.left +=
      parseFloat($(offsetParent[0]).css("border-left-width")) || 0;

    // Subtract the two offsets
    return {
      top: offset.top - parentOffset.top,
      left: offset.left - parentOffset.left,
    };
  };

  $.ajax = async function (args) {
    var url = args.url;
    var options = {};
    var headers = {};
    if (args.hasOwnProperty("type")) {
      options.method = args.type;
    }
    if (args.hasOwnProperty("signal")) {
      options.signal = args.signal;
    }
    if (args.hasOwnProperty("contentType")) {
      headers["Content-type"] = args.contentType;
    }
    options.headers = headers;
    if (args.hasOwnProperty("data")) {
      if (typeof args.data === "string" || args.data instanceof String) {
        options.body = args.data;
      } else {
        if (args.hasOwnProperty("contentType") && args.contentType.indexOf("x-www-form-urlencoded") > 0) {
          const formData = new FormData();
          for (const [key, value] of Object.entries(args.data)) {
            formData.append(key, value);
          }
          options.body = new URLSearchParams(formData);
        } else {
          options.body = JSON.stringify(args.data);
        }
      }
    }
    fetch(url, options)
      .then((response) => {
        return response.json();
      }).then((json) => {
        if (typeof args.success === "function") args.success(json);
      }).catch((error) => {
        if (typeof args.error === "function") args.error(error);
      });
  };

  $.get = async function (url, handlerSuccess, handlerError) {
    fetch(url)
      .then((response) => {
        if(response.headers.get('Content-Type').indexOf('text/html') > -1){
            return response.text();
        }
        return response.json();
      })
      .then((json) => {
        if (typeof handlerSuccess === "function") handlerSuccess(json);
      }).catch((error) => {
        if (typeof handlerError === "function") handlerError(error);
      });
  };

  $.post = async function (url, data, handlerSuccess, handlerError) {
    var options = {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
      },
    };
    if (data) {
      const formData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value);
      }
      options.body = new URLSearchParams(formData);
    }
    fetch(url, options)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (typeof handlerSuccess === "function") handlerSuccess(json);
      })
      .catch((error) => {
        console.error(error);
        if (typeof handlerError === "function") handlerError(error);
      });
  };

  const touch = matchMedia("(hover: none), (pointer: coarse)").matches;
  $.isTouchCapable = function () {
    return touch;
  };

  $.param = function (obj) {
    url = Object.keys(obj).map(function(k) {
      return encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])
    }).join('&');
    return url;
  };

  $.noop = function () {};

  $.fn.isVisible = function () {
    elem = this[0];
    if (!elem) {
      return null;
    }
    return $.isVisible(elem);
  };

  $.isVisible = function (elem) {
    const styles = window.getComputedStyle(elem);
    const width = elem.offsetWidth,
      height = elem.offsetHeight;
    const ret = (width === 0 && height === 0) || styles.display === "none";
    return !ret;
  };

  $.fn.filterVisible = function () {
    const n = this.length;
    for (i = n - 1; i >= 0; i--) {
      if (!$.isVisible(this[i])) {
        this.splice(i, 1);
      }
    }
    return this;
  };

  $.fn.followTo = function(pos, topHeight) {
    var $this, $window;
    $this = this;
    $window = $(window);
    $window.scroll(function(e) {
      if ($window.scrollTop() > pos) {
        $this.css({
          position: 'absolute',
          top: pos + topHeight
        });
      } else {
        $this.css({
          position: 'fixed',
          top: topHeight
        });
      }
    });
  };

  $.fn.filterSelected = function () {
    const n = this.length;
    for (i = n - 1; i >= 0; i--) {
      if (!this[i].selected) {
        this.splice(i, 1);
      }
    }
    return this;
  }

  $.fn.change = function () {
    this.each(function () {
      this.dispatchEvent(new Event("change"));
    });
  }
  
  $.fn.focus = function (options) {
    this.each(function () {
      this.focus(options);
    });
  }

  $.fn.svgSwap = function (a, b) {
    this.each(function () {
      var use = this.querySelector('use');
      if(!use) return;
      if(use.getAttribute('href') == '/icons.svg#' + a)
      use.setAttribute('href', '/icons.svg#' + b)
      else
      use.setAttribute('href', '/icons.svg#' + a)
    });
    return this;
  }

  $.fn.svgSet = function (a) {
    this.each(function () {
      var use = this.querySelector('use');
      if(!use) return;
      use.setAttribute('href', '/icons.svg#' + a)
    });
    return this;
  }

  $.fn.fadeIn = function (duration = 500, callback) {
    var element = this[0];
    if(!element) return;
    if(element.getAttribute('data-fade-in') == 'true') return;
    element.setAttribute('data-fade-in', 'true');
    element.style.display = '';
    element.style.opacity = 0;
    var last = +new Date();
    var tick = function() {
      element.style.opacity = +element.style.opacity + (new Date() - last) / duration;
      last = +new Date();
      if (+element.style.opacity < 1) {
        (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
      } else {
        element.setAttribute('data-fade-in', 'true');
        element.style.opacity = 1;
        element.style.display = 'block';
        if (typeof callback === "function") callback();
      }
    };
    tick();
  }
  $.fn.fadeOut = function (duration = 500, callback) {
    var element = this[0];
    if(!element) return;
    if(element.getAttribute('data-fade-out') == 'true') return;
    element.setAttribute('data-fade-out', 'true');
    element.style.display = '';
    element.style.opacity = 1;
    let last = +new Date();
    let tick = function() {
      element.style.opacity = Number(+element.style.opacity - (new Date() - last) / duration).toFixed(4);
      last = +new Date();
      if (-element.style.opacity <= 0) {
        (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
      }else{
          element.setAttribute('data-fade-out', 'true');
          element.style.opacity = 0;
          element.style.display = 'none';
          if (typeof callback === "function") callback();
      }
    };
    tick();
  }

  $.fn.onSwipe = function (callback) {

    if(!$.isTouchCapable()) return;
    var swipeDir,
    startX,
    startY,
    distX,
    distY,
    threshold = 100, //required min distance traveled to be considered swipe
    restraint = 100, // maximum distance allowed at the same time in perpendicular direction
    allowedTime = 300, // maximum time allowed to travel that distance
    elapsedTime,
    startTime,
    handleSwipe = callback || function(e){},
    onTouchStart = function(e){
        var touchObj = e.changedTouches[0]
        swipeDir = 'none'
        dist = 0
        startX = touchObj.pageX
        startY = touchObj.pageY
        startTime = new Date().getTime() // record time when finger first makes contact with surface
        //e.preventDefault()
    },
    onTouchMove = function(e){
      //e.preventDefault() // prevent scrolling when inside DIV
    },
    onTouchEnd = function(e){
        var touchObj = e.changedTouches[0]
        distX = touchObj.pageX - startX // get horizontal dist traveled by finger while in contact with surface
        distY = touchObj.pageY - startY // get vertical dist traveled by finger while in contact with surface
        elapsedTime = new Date().getTime() - startTime // get time elapsed
        if (elapsedTime <= allowedTime){ // first condition for swipe met
            if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint){ // 2nd condition for horizontal swipe met
                swipeDir = (distX < 0)? 'left' : 'right' // if dist traveled is negative, it indicates left swipe
            }
            else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint){ // 2nd condition for vertical swipe met
                swipeDir = (distY < 0)? 'up' : 'down' // if dist traveled is negative, it indicates up swipe
            }
        }
        handleSwipe(e.currentTarget, swipeDir)
        if(swipeDir != 'none')
          e.preventDefault()
    }
    this.each(function () {
      this.addEventListener('touchstart', onTouchStart, false)
      this.addEventListener('touchmove', onTouchMove, false)
      this.addEventListener('touchend', onTouchEnd, false)
    });
}

})(cash);
