/*
* $ lightbox_me
* By: Buck Wilson
* Version : 2.4
*
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*     http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
*/
    (function($) {
        $.fn.lightbox_me = function(options) {

            return this.each(function() {
                var
                    opts = $.extend({}, $.fn.lightbox_me.defaults, options),
                    $overlay = $(),
                    $self = $(this),
                    $iframe = $('<iframe id="foo" style="z-index: ' + (opts.zIndex + 1) + ';border: none; margin: 0; padding: 0; position: absolute; width: 100%; height: 100%; top: 0; left: 0; filter: mask();"/>');

                if (opts.showOverlay) {
                    //check if there's an existing overlay, if so, make subequent ones clear
                var $currentOverlays = $(".js_lb_overlay").filterVisible();
                    if ($currentOverlays.length > 0){
                        $overlay = $('<div class="lb_overlay_clear js_lb_overlay"/>');
                    } else {
                        $overlay = $('<div class="' + opts.classPrefix + '_overlay js_lb_overlay"/>');
                    }
                }

                /*----------------------------------------------------
                DOM Building
                ---------------------------------------------------- */
                $('body').append($self.hide()).append($overlay);


                /*----------------------------------------------------
                Overlay CSS stuffs
                ---------------------------------------------------- */

                // set css of the overlay
                if (opts.showOverlay) {
                    setOverlayHeight(); // pulled this into a function because it is called on window resize.
                    $overlay.css({ position: 'absolute', width: '100%', top: 0, left: 0, right: 0, bottom: 0, zIndex: (opts.zIndex + 2), display: 'none' });
                    if (!$overlay.hasClass('lb_overlay_clear')){
                        $overlay.css(opts.overlayCSS);
                    }
                }

                /*----------------------------------------------------
                Animate it in.
                ---------------------------------------------------- */
                //
                if (opts.showOverlay) {
                    $overlay.show();
                    setSelfPosition();
                    $self.show();
                    setOverlayHeight();
                    setSelfPosition();
                    opts.onLoad();
                } else {
                    setSelfPosition();
                    $self.show();
                    opts.onLoad();
                }

                /*----------------------------------------------------
                Hide parent if parent specified (parentLightbox should be jquery reference to any parent lightbox)
                ---------------------------------------------------- */
                if (opts.parentLightbox) {
                    opts.parentLightbox.hide();
                }


                /*----------------------------------------------------
                Bind Events
                ---------------------------------------------------- */

                $(window).on('resize', setOverlayHeight)
                        .on('resize', setSelfPosition)
                        .on('scroll', setSelfPosition);

                $(window).on('keyup.lightbox_me', observeKeyPress);

                if (opts.closeClick) {
                    $overlay.on('click', function(e) { closeLightbox(); e.preventDefault; });
                }
                $self.on('click', opts.closeSelector, function(e) {
                    closeLightbox(); e.preventDefault();
                });
                $self.on('close', closeLightbox);
                $self.on('reposition', setSelfPosition);



                /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */


                /*----------------------------------------------------
                Private Functions
                ---------------------------------------------------- */

                /* Remove or hide all elements */
                function closeLightbox() {
                    document.getElementsByTagName('body')[0].className='';
                    if(typeof App.order !== undefined && App.order !== undefined && App.order !== null){
                        var refreshCart = App.order.get('refreshCart');
                        if(refreshCart !== null){
                            var email = App.order.get('email');
                            var json_upload = "email=" + email;
                            var xmlhttp = new XMLHttpRequest();
                            xmlhttp.open("POST", "/remove-all-carts");
                            xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                            xmlhttp.send(json_upload);
                        }
                    }
                    var s = $self[0].style;
                    if (opts.destroyOnClose) {
                        $self.add($overlay).remove();
                    } else {
                        $self.add($overlay).hide();
                    }

                    //show the hidden parent lightbox
                    if (opts.parentLightbox) {
                        opts.parentLightbox.show();
                    }
                    if (opts.preventScroll) {
                        $('body').css('overflow', '');
                    }
                    $iframe.remove();

                    if (opts.destroyOnClose) {
                    // clean up events.
                        $self.off('click', opts.closeSelector);
                        $self.off('close', closeLightbox);
                        $self.off('repositon', setSelfPosition);
                        $(window).off('resize', setOverlayHeight);
                        $(window).off('resize', setSelfPosition);
                        $(window).off('scroll', setSelfPosition);
                        $(window).off('keyup.lightbox_me');
                    }
                    opts.onClose();
                }


                /* Function to bind to the window to observe the escape/enter key press */
                function observeKeyPress(e) {
                    if((e.keyCode == 27 || (e.DOM_VK_ESCAPE == 27 && e.which==0)) && opts.closeEsc) closeLightbox();
                }


                /* Set the height of the overlay
                        : if the document height is taller than the window, then set the overlay height to the document height.
                        : otherwise, just set overlay height: 100%
                */
                function setOverlayHeight() {
                    if ($(window).height() < $(document).height()) {
                        $overlay.css({height: $(document).height() + 'px'});
                        $iframe.css({height: $(document).height() + 'px'});
                    } else {
                        $overlay.css({height: '100%'});
                    }
                }


                /* Set the position of the modal'd window ($self)
                        : if $self is taller than the window, then make it absolutely positioned
                        : otherwise fixed
                */
                function setSelfPosition() {
                    var s = $self[0].style;

                    // reset CSS so width is re-calculated for margin-left CSS
                    $self.css({left: '50%', marginLeft: ($self.outerWidth() / 2) * -1,  zIndex: (opts.zIndex + 3) });


                    /* we have to get a little fancy when dealing with height, because lightbox_me
                        is just so fancy.
                    */

                    // if the height of $self is bigger than the window and self isn't already position absolute
                    if (($self.height() + 80  >= $(window).height()) && ($self.css('position') != 'absolute')) {

                        // we are going to make it positioned where the user can see it, but they can still scroll
                        // so the top offset is based on the user's scroll position.
                        var topOffset = $(document).scrollTop() + 40;
                        $self.css({position: 'absolute', top: topOffset + 'px', marginTop: 0})
                    } else if ($self.height()+ 80  < $(window).height()) {
                        //if the height is less than the window height, then we're gonna make this thing position: fixed.
                        if (opts.centered) {
                            $self.css({ position: 'fixed', top: '50%', marginTop: ($self.outerHeight() / 2) * -1})
                        } else {
                            $self.css({ position: 'fixed'}).css(opts.modalCSS);
                        }
                        if (opts.preventScroll) {
                            $('body').css('overflow', 'hidden');
                        }
                    }
                }

            });



        };

        $.fn.lightbox_me.defaults = {

            // animation
            appearEffect: "fadeIn",
            appearEase: "",
            overlaySpeed: 250,
            lightboxSpeed: 300,

            // close
            closeSelector: ".close",
            closeClick: true,
            closeEsc: true,

            // behavior
            destroyOnClose: false,
            showOverlay: true,
            parentLightbox: false,
            preventScroll: false,

            // callbacks
            onLoad: function() {},
            onClose: function() {},

            // style
            classPrefix: 'lb',
            zIndex: 999,
            centered: false,
            modalCSS: {top: '40px'},
            overlayCSS: {background: 'black', opacity: .3}
        }
    })(cash);